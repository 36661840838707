import React, { useState } from "react";
import styles from "./style.module.scss";
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
} from "./steps";
import { StepperPointer } from "../../../ui";

const FleetCardMemberForm = ({ loader, success }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Step One data
    stepOne: {},
    // Step Two data
    stepTwo: {},
    // Step Three data
    stepThree: {},
    // Step Four data
    stepFour: {},
    // Step Five data
    stepFive: {},
    // Step Six data
    stepSix: {},
  });

  const scrollToTop = () => {
    const element = document.querySelector("#fleetCardMember");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    scrollToTop();
  };

  const prevStep = () => {
    setStep(step - 1);
    scrollToTop();
  };

  const handleFormData = (stepKey) => (values) => {
    setFormData((prev) => ({
      ...prev,
      [stepKey]: values,
    }));
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            next={nextStep}
            data={handleFormData("stepOne")}
            savedValues={formData.stepOne}
          />
        );
      case 2:
        return (
          <StepSix
            next={nextStep}
            prev={prevStep}
            data={handleFormData("stepSix")}
            savedValues={formData.stepSix}
          />
        );
      case 3:
        return (
          <StepTwo
            next={nextStep}
            prev={prevStep}
            data={handleFormData("stepTwo")}
            savedValues={formData.stepTwo}
          />
        );
      case 4:
        return (
          <StepThree
            next={nextStep}
            prev={prevStep}
            data={handleFormData("stepThree")}
            savedValues={formData.stepThree}
          />
        );
      case 5:
        return (
          <StepFour
            next={nextStep}
            prev={prevStep}
            data={handleFormData("stepFour")}
            savedValues={formData.stepFour}
          />
        );
      case 6:
        return (
          <StepFive
            next={nextStep}
            prev={prevStep}
            data={handleFormData("stepFive")}
            savedValues={formData.stepFive}
          />
        );
      case 7:
        return (
          <StepSeven
            // next={nextStep}
            prev={prevStep}
            // data={handleFormData("stepSeven")}
            // savedValues={formData.stepSeven}
            prevData={formData}
            isSubmitting={loader}
            isSuccess={success}
          />
        );
      default:
        return null;
    }
  };

  const totalSteps = [
    {
      id: 1,
      name: "Business Information",
    },
    {
      id: 2,
      name: "Fleet Information",
    },
    {
      id: 3,
      name: "Mailing Address",
    },
    {
      id: 4,
      name: "Contact Details",
    },
    {
      id: 5,
      name: "Trade Reference",
    },
    {
      id: 6,
      name: "Banking Information",
    },
    {
      id: 7,
      name: "Legal Sign Off",
    },
  ];

  return (
    <div className={styles.fleetCardMemberForm} id="fleetCardMember">
      <StepperPointer
        currentStep={step}
        totalSteps={totalSteps?.length}
        stepsData={totalSteps}
      />
      {renderStep()}
    </div>
  );
};

export default FleetCardMemberForm;
