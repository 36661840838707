/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { fleetMemberStepOneValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  // SectionTitle,
  Select,
} from "../../../../../styledComponent";
import { ChevronRight } from "react-bootstrap-icons";
import styles from "../../style.module.scss";

const StepOne = (props) => {
  const formik = useFormik({
    initialValues: {
      business_name: props.savedValues?.business_name || "",
      operating_as: props.savedValues?.operating_as || "",
      business_type: props.savedValues?.business_type || "",
      years_of_company: props.savedValues?.years_of_company || "",
      years_of_operation: props.savedValues?.years_of_operation || "",
      hst_exempt: props.savedValues?.hst_exempt || "",
      // expected_fuel_volume: props.savedValues?.expected_fuel_volume || "",
      // no_of_cards_needed: props.savedValues?.no_of_cards_needed || "",
      gst_number: props.savedValues?.gst_number || "",
      hst_exempt_document: props.savedValues?.hst_exempt_document || "",
    },
    validationSchema: fleetMemberStepOneValidationSchema,
    onSubmit: (values) => {
      props.next();
      props.data(values);
    },
  });

  useEffect(() => {
    console.log("props.savedValues", props.savedValues);
    formik.resetForm({
      business_name: props.savedValues?.business_name || "",
      business_type: props.savedValues?.business_type || "",
      years_of_company: props.savedValues?.years_of_company || "",
      years_of_operation: props.savedValues?.years_of_operation || "",
      hst_exempt: props.savedValues?.hst_exempt || "",
      expected_fuel_volume: props.savedValues?.expected_fuel_volume || "",
      no_of_cards_needed: props.savedValues?.no_of_cards_needed || "",
      gst_number: props.savedValues?.gst_number || "",
      hst_exempt_document: props.savedValues?.hst_exempt_document || "",
    });
  }, [props.savedValues]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <div>
        <div>
          <Label htmlFor="business_name">Complete Legal Business Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("business_name")}
            style={
              formik.touched.business_name &&
              formik.errors.business_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.business_name && formik.errors.business_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.business_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="operating_as">Operating as (if applicable)</Label>
          <Input
            type="text"
            {...formik.getFieldProps("operating_as")}
            style={
              formik.touched.operating_as &&
              formik.errors.operating_as && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.operating_as && formik.errors.operating_as && (
            <ErrorText className={styles.errorText}>
              {formik.errors.operating_as}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="business_type">Are you a*</Label>
          <Select
            {...formik.getFieldProps("business_type")}
            style={
              formik.touched.business_type &&
              formik.errors.business_type && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          >
            <option value={"select"}>Select</option>
            <option value={"sole_proprietorship"}>Sole Proprietorship</option>
            <option value={"partnership"}>Partnership</option>
            <option value={"incorporated"}>Incorporated</option>
          </Select>
          {formik.touched.business_type && formik.errors.business_type && (
            <ErrorText className={styles.errorText}>
              {formik.errors.business_type}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="years_of_company">
            How long has this company been in business?*
          </Label>
          <Input
            type="number"
            placeholder="in years"
            name="years_of_company"
            onChange={(e) =>
              formik.setFieldValue("years_of_company", parseInt(e.target.value))
            }
            value={formik.values.years_of_company}
            onBlur={formik.handleBlur}
            style={
              formik.touched.years_of_company &&
              formik.errors.years_of_company && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.years_of_company &&
            formik.errors.years_of_company && (
              <ErrorText className={styles.errorText}>
                {formik.errors.years_of_company}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="years_of_operation">
            How long have you operated this business?*
          </Label>
          <Input
            type="number"
            placeholder="in years"
            name="years_of_operation"
            value={formik.values.years_of_operation}
            onChange={(e) =>
              formik.setFieldValue(
                "years_of_operation",
                parseInt(e.target.value)
              )
            }
            onBlur={formik.handleBlur}
            // {...formik.getFieldProps("years_of_operation")}
            style={
              formik.touched.years_of_operation &&
              formik.errors.years_of_operation && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.years_of_operation &&
            formik.errors.years_of_operation && (
              <ErrorText className={styles.errorText}>
                {formik.errors.years_of_operation}
              </ErrorText>
            )}
        </div>
        <div>
          <Label htmlFor="hst_exempt">Is your business GST/HST exempt?*</Label>
          <Select
            id="hst_exempt"
            name="hst_exempt"
            // value={formik.values.hst_exempt}
            // onChange={(e) => {
            //   if (e.target.value === "yes") {
            //     formik.setFieldValue("hst_exempt", true);
            //   }
            //   if (e.target.value === "no") {
            //     formik.setFieldValue("hst_exempt", false);
            //   }
            // }}
            {...formik.getFieldProps("hst_exempt")}
            style={
              formik.touched.hst_exempt &&
              formik.errors.hst_exempt && { boxShadow: "0px 0px 0px 2px red" }
            }
          >
            <option value={"select"}>Select</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
          </Select>
          {formik.touched.hst_exempt && formik.errors.hst_exempt && (
            <ErrorText className={styles.errorText}>
              {formik.errors.hst_exempt}
            </ErrorText>
          )}
        </div>
      </div>
      {formik.values.hst_exempt === "no" && (
        <div>
          <div>
            <Label htmlFor="gst_number">GST Number*</Label>
            <Input
              type="text"
              {...formik.getFieldProps("gst_number")}
              style={
                formik.touched.gst_number &&
                formik.errors.gst_number && {
                  boxShadow: "0px 0px 0px 2px red",
                }
              }
            />
            {formik.touched.gst_number && formik.errors.gst_number && (
              <ErrorText className={styles.errorText}>
                {formik.errors.gst_number}
              </ErrorText>
            )}
          </div>
        </div>
      )}

      {formik.values.hst_exempt === "yes" && (
        <div className={styles.uploadFileContainer}>
          <div className="uploadLogoContainer uploadVendorFile">
            <div className="uploadContainer">
              <input
                type="file"
                name="hst_exempt_document"
                onChange={(e) => {
                  formik.setFieldValue(
                    "hst_exempt_document",
                    e.currentTarget.files[0]
                  );
                }}
                onBlur={formik.handleBlur("hst_exempt_document")}
              />
              <label>Upload</label>
              <span id="fileChosen">
                {formik.values.hst_exempt_document?.name ||
                  "Upload GST/HST Exempt Document"}
              </span>
            </div>
          </div>
          {formik.touched.hst_exempt_document &&
            formik.errors.hst_exempt_document && (
              <ErrorText className={styles.errorText}>
                {formik.errors.hst_exempt_document}
              </ErrorText>
            )}
        </div>
      )}
      {/* <div>
        <div>
          <Label htmlFor="expected_fuel_volume">
            Expected Fuel Volume (Monthly)*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("expected_fuel_volume")}
            style={
              formik.touched.expected_fuel_volume &&
              formik.errors.expected_fuel_volume && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.expected_fuel_volume &&
            formik.errors.expected_fuel_volume && (
              <ErrorText className={styles.errorText}>
                {formik.errors.expected_fuel_volume}
              </ErrorText>
            )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="no_of_cards_needed">Number of cards needed*</Label>
          <Input
            type="number"
            name="no_of_cards_needed"
            onChange={(e) =>
              formik.setFieldValue(
                "no_of_cards_needed",
                parseInt(e.target.value)
              )
            }
            onBlur={formik.handleBlur}
            // {...formik.getFieldProps("no_of_cards_needed")}
            style={
              formik.touched.no_of_cards_needed &&
              formik.errors.no_of_cards_needed && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.no_of_cards_needed &&
            formik.errors.no_of_cards_needed && (
              <ErrorText className={styles.errorText}>
                {formik.errors.no_of_cards_needed}
              </ErrorText>
            )}
        </div>
      </div> */}
      <div className={`${styles.formBtnContainer} ${styles.oneBtnContainer}`}>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepOne;
