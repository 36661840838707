import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";
import styles from "../../style.module.scss";

// Validation schema for the new fleet information form
const fleetInformationValidationSchema = Yup.object({
  size_of_fleet: Yup.string().required("This is a required field"),
  types_of_vehicles: Yup.string().required("This is a required field"),
  volume_per_month: Yup.string().required("This is a required field"),
  number_of_cards_requested: Yup.string().required("This is a required field"),
  daily_usage_per_card: Yup.string()
    .required("This is a required field")
    .matches(
      /^\$\d{1,3}(,\d{3})*(\.\d{0,2})?$/,
      "Please enter a valid dollar amount"
    ),
});

const StepSix = (props) => {
  const formik = useFormik({
    initialValues: {
      size_of_fleet: props.savedValues?.size_of_fleet || "",
      types_of_vehicles: props.savedValues?.types_of_vehicles || "",
      volume_per_month: props.savedValues?.volume_per_month || "",
      number_of_cards_requested:
        props.savedValues?.number_of_cards_requested || "",
      daily_usage_per_card: props.savedValues?.daily_usage_per_card || "",
    },
    validationSchema: fleetInformationValidationSchema,
    onSubmit: (values) => {
      props.next();
      props.data(values);
    },
  });

  const handleBack = () => {
    props.data(formik.values);
    props.prev();
  };

  const handleDailyUsageChange = (e) => {
    let value = e.target.value;

    // Remove all formatting except numbers and decimal point
    value = value.replace(/[^0-9.]/g, "");

    // Prevent multiple decimal points
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      return;
    }

    // Handle the decimal point cases
    if (value === ".") {
      formik.setFieldValue("daily_usage_per_card", "$0.");
      return;
    }

    // Ensure only two decimal places
    if (value.includes(".")) {
      const [whole, decimal] = value.split(".");
      if (decimal && decimal.length > 2) {
        value = `${whole}.${decimal.slice(0, 2)}`;
      }
    }

    // Format the number if it's valid
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      let formatted = numValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: value.includes(".")
          ? (value.split(".")[1] || "").length
          : 0,
        maximumFractionDigits: 2,
      });

      // If user is typing a decimal number, keep the decimal point
      if (value.endsWith(".")) {
        formatted = formatted + ".";
      }

      formik.setFieldValue("daily_usage_per_card", formatted);
    } else if (value === "") {
      formik.setFieldValue("daily_usage_per_card", "");
    }
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>
        Fleet Information
      </SectionTitle>

      <div>
        <div>
          <Label htmlFor="size_of_fleet">Size of Fleet?*</Label>
          <Input
            type="number"
            {...formik.getFieldProps("size_of_fleet")}
            style={
              formik.touched.size_of_fleet &&
              formik.errors.size_of_fleet && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.size_of_fleet && formik.errors.size_of_fleet && (
            <ErrorText className={styles.errorText}>
              {formik.errors.size_of_fleet}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="types_of_vehicles">Types of Vehicles?*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("types_of_vehicles")}
            style={
              formik.touched.types_of_vehicles &&
              formik.errors.types_of_vehicles && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.types_of_vehicles &&
            formik.errors.types_of_vehicles && (
              <ErrorText className={styles.errorText}>
                {formik.errors.types_of_vehicles}
              </ErrorText>
            )}
        </div>
      </div>

      <div>
        <div>
          <Label htmlFor="volume_per_month">
            Total anticipated volume per month for entire Fleet?*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("volume_per_month")}
            placeholder="Litres"
            style={
              formik.touched.volume_per_month &&
              formik.errors.volume_per_month && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.volume_per_month &&
            formik.errors.volume_per_month && (
              <ErrorText className={styles.errorText}>
                {formik.errors.volume_per_month}
              </ErrorText>
            )}
        </div>
        <div>
          <Label htmlFor="number_of_cards_requested">
            Number of Cards requested?*
          </Label>
          <Input
            type="number"
            {...formik.getFieldProps("number_of_cards_requested")}
            style={
              formik.touched.number_of_cards_requested &&
              formik.errors.number_of_cards_requested && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.number_of_cards_requested &&
            formik.errors.number_of_cards_requested && (
              <ErrorText className={styles.errorText}>
                {formik.errors.number_of_cards_requested}
              </ErrorText>
            )}
        </div>
      </div>

      <div>
        <div>
          <Label htmlFor="daily_usage_per_card">
            Expected single transaction per card in terms of dollar value?
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("daily_usage_per_card")}
            onChange={handleDailyUsageChange}
            placeholder="$0.00"
            style={
              formik.touched.daily_usage_per_card &&
              formik.errors.daily_usage_per_card && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.daily_usage_per_card &&
            formik.errors.daily_usage_per_card && (
              <ErrorText className={styles.errorText}>
                {formik.errors.daily_usage_per_card}
              </ErrorText>
            )}
        </div>
      </div>

      <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
        <Button
          type="button"
          onClick={handleBack}
          className={styles.backButton}
        >
          <ChevronLeft /> Back
        </Button>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepSix;
