import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
// import { fleetMemberStepFourInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepFourValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import styles from "../../style.module.scss";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";

const StepFour = (props) => {
  const [postcode, setPostcode] = useState("");

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const d = new Date(dateString);
    if (isNaN(d.getTime())) return ""; // Return empty string if invalid date
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formik = useFormik({
    initialValues: {
      first_name: props.savedValues?.first_name || "",
      last_name: props.savedValues?.last_name || "",
      dob: formatDateForInput(props.savedValues?.dob) || "",
      street: props.savedValues?.street || "",
      apartment: props.savedValues?.apartment || "",
      city: props.savedValues?.city || "",
      province: props.savedValues?.province || "",
      postcode: props.savedValues?.postcode || "",
      sin: props.savedValues?.sin || "",
    },
    validationSchema: fleetMemberStepFourValidationSchema,
    onSubmit: (values) => {
      const formattedDate = formatDate(values.dob);
      console.log("formattedDate", formattedDate, values.dob);
      props.data({ ...values, dob: formattedDate });
      props.next();
      // console.log({ ...values, dob: formattedDate });
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formik.setFieldValue("postcode", postcode);
    }, 1000);

    return () => clearTimeout(timer);
  }, [postcode, formik]);

  useEffect(() => {
    setPostcode(props.savedValues?.postcode || "");
  }, [props.savedValues]);

  function formatDate(date) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const formatPostalCode = (value) => {
    let cleaned = value.replace(/[^A-Z0-9\s]/gi, "").toUpperCase();

    cleaned = cleaned.replace(/\s/g, "");

    let formatted = "";
    for (let i = 0; i < cleaned.length && i < 6; i++) {
      if (i === 0 || i === 2 || i === 4) {
        if (!/[A-Z]/.test(cleaned[i])) {
          break;
        }
        formatted += cleaned[i];
      } else if (i === 1 || i === 3 || i === 5) {
        if (!/[0-9]/.test(cleaned[i])) {
          break;
        }
        formatted += cleaned[i];
      }

      if (i === 2) {
        formatted += " ";
      }
    }

    return formatted;
  };

  const handleBack = () => {
    props.data(formik.values);
    props.prev();
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>Trade Reference</SectionTitle>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="dob">Date of Birth*</Label>
          <Input
            type="date"
            name="dob"
            {...formik.getFieldProps("dob")}
            style={
              formik.touched.dob &&
              formik.errors.dob && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.dob && formik.errors.dob && (
            <ErrorText className={styles.errorText}>
              {formik.errors.dob}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            placeholder="If applicable"
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="postcode">Postal Code*</Label>
          <Input
            type="text"
            name="postcode"
            id="postcode"
            placeholder="A1A 1A1"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setPostcode(formatPostalCode(e.target.value));
            }}
            value={postcode}
            style={
              formik.touched.postcode &&
              formik.errors.postcode && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.postcode && formik.errors.postcode && (
            <ErrorText className={styles.errorText}>
              {formik.errors.postcode}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="sin">SIN*</Label>
          <Input
            type="number"
            {...formik.getFieldProps("sin")}
            style={
              formik.touched.sin &&
              formik.errors.sin && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.sin && formik.errors.sin && (
            <ErrorText className={styles.errorText}>
              {formik.errors.sin}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
        <Button
          type="button"
          onClick={handleBack}
          className={styles.backButton}
        >
          <ChevronLeft /> Back
        </Button>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepFour;
