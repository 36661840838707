import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
// import { fleetMemberStepTwoInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepTwoValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import { normalizeInput } from "../../../../../../utils/helpers";
import styles from "../../style.module.scss";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";

const StepTwo = (props) => {
  const [businessPhone, setBusinessPhone] = useState("");
  const [cell, setCell] = useState("");
  const [postcode, setPostcode] = useState("");
  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      street: props.savedValues?.street || "",
      apartment: props.savedValues?.apartment || "",
      city: props.savedValues?.city || "",
      province: props.savedValues?.province || "",
      postcode: props.savedValues?.postcode || "",
      email: props.savedValues?.email || "",
      business_phone: props.savedValues?.business_phone || "",
      cell: props.savedValues?.cell || "",
    },
    validationSchema: fleetMemberStepTwoValidationSchema,
    onSubmit: (values) => {
      props.next();
      props.data(values);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("business_phone", businessPhone);
    }, 1000);

    return () => clearTimeout(timer);
  }, [businessPhone, formik]);

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("cell", cell);
    }, 1000);

    return () => clearTimeout(timer);
  }, [cell, formik]);

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("postcode", postcode);
    }, 1000);

    return () => clearTimeout(timer);
  }, [postcode, formik]);

  useEffect(() => {
    setBusinessPhone(props.savedValues?.business_phone || "");
    setCell(props.savedValues?.cell || "");
    setPostcode(props.savedValues?.postcode || "");
  }, [props.savedValues]);

  const handleBack = () => {
    props.data(formik.values);
    props.prev();
  };

  const formatPostalCode = (value) => {
    // Remove all non-alphanumeric characters except spaces
    let cleaned = value.replace(/[^A-Z0-9\s]/gi, "").toUpperCase();

    // Remove all spaces first
    cleaned = cleaned.replace(/\s/g, "");

    // Apply the pattern character by character
    let formatted = "";
    for (let i = 0; i < cleaned.length && i < 6; i++) {
      // Positions 0, 2, 4 should be letters (A-Z)
      if (i === 0 || i === 2 || i === 4) {
        if (!/[A-Z]/.test(cleaned[i])) {
          break;
        }
        formatted += cleaned[i];
      }
      // Positions 1, 3, 5 should be numbers (0-9)
      else if (i === 1 || i === 3 || i === 5) {
        if (!/[0-9]/.test(cleaned[i])) {
          break;
        }
        formatted += cleaned[i];
      }

      // Add space after third character
      if (i === 2) {
        formatted += " ";
      }
    }

    return formatted;
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>Mailing Address</SectionTitle>
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt.</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            placeholder="If applicable"
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="postcode">Postal Code*</Label>
          <Input
            type="text"
            name="postcode"
            id="postcode"
            placeholder="A1A 1A1"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setPostcode((prev) => formatPostalCode(e.target.value));
            }}
            value={postcode}
            style={
              formik.touched.postcode &&
              formik.errors.postcode && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.postcode && formik.errors.postcode && (
            <ErrorText className={styles.errorText}>
              {formik.errors.postcode}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="email">Email*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("email")}
            style={
              formik.touched.email &&
              formik.errors.email && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.email && formik.errors.email && (
            <ErrorText className={styles.errorText}>
              {formik.errors.email}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="business_phone">Business Phone*</Label>
          <Input
            type="text"
            name="business_phone"
            id="business_phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setBusinessPhone((prev) => normalizeInput(e.target.value, prev));
            }}
            value={businessPhone}
            style={
              formik.touched.business_phone &&
              formik.errors.business_phone && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.business_phone && formik.errors.business_phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.business_phone}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="cell">Cell*</Label>
          <Input
            type="text"
            name="cell"
            id="cell"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setCell((prev) => normalizeInput(e.target.value, prev));
            }}
            value={cell}
            style={
              formik.touched.cell &&
              formik.errors.cell && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.cell && formik.errors.cell && (
            <ErrorText className={styles.errorText}>
              {formik.errors.cell}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
        <Button
          type="button"
          onClick={handleBack}
          className={styles.backButton}
        >
          <ChevronLeft /> Back
        </Button>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepTwo;
