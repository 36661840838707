import React from "react";
import styles from "./style.module.scss";

const StepperPointer = (props) => {
  // Generate steps based on totalSteps prop
  const renderSteps = () => {
    const steps = [];
    const totalSteps = props.totalSteps || 2; // Default to 2 if not provided
    const stepsData = props.stepsData || []; // Array of step data objects

    for (let i = 1; i <= totalSteps; i++) {
      // Find the step data for current index if available
      const stepData = stepsData.find((step) => step.id === i);
      
      // Check if step is completed (any step with id less than currentStep)
      const isCompleted = stepData?.id < props.currentStep;

      steps.push(
        <>
          <div key={i} className={` ${styles.stepBlock}`}>
            <span
              className={
                (props.currentStep === stepData?.id || isCompleted) && styles.active
              }
            >
              {isCompleted ? "✓" : stepData?.id}
            </span>
            <span>{stepData?.name || ""}</span>
          </div>
          {/* <hr/> */}
          {/* <span className={styles.middleBorder}></span> */}
        </>
      );
    }

    return steps;
  };

  return (
    <div className={`${styles.stepPointerBlock} ${props.className}`}>
      {renderSteps()}
    </div>
  );
};

export default StepperPointer;
