import React from 'react';
import styles from "./style.module.scss";

const StepPointer = (props) => {
  // Generate steps based on totalSteps prop
  const renderSteps = () => {
    const steps = [];
    const totalSteps = props.totalSteps || 2; // Default to 2 if not provided
    
    for (let i = 1; i <= totalSteps; i++) {
      steps.push(
        <span key={i} className={props.currentStep === i && styles.active}>
          {i}
        </span>
      );
    }
    
    return steps;
  };

  return (
    <div className={`${styles.stepPointerBlock} ${props.className}`}>
      {renderSteps()}
    </div>
  )
}

export default StepPointer;