import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { fleetMemberStepThreeInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepThreeValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import { ChevronRight, ChevronLeft } from "react-bootstrap-icons";
import { normalizeInput } from "../../../../../../utils/helpers";
import styles from "../../style.module.scss";

const StepThree = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(
    props.savedValues?.contact_person_phone || ""
  );
  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      ...fleetMemberStepThreeInitialValues,
      ...props.savedValues,
    },
    validationSchema: fleetMemberStepThreeValidationSchema,
    onSubmit: (values) => {
      props.next();
      props.data(values);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("contact_person_phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  const handleBack = () => {
    props.data({
      ...formik.values,
      contact_person_phone: phoneNumber,
    });
    props.prev();
  };

  useEffect(() => {
    setPhoneNumber(props.savedValues?.contact_person_phone || "");
  }, [props.savedValues]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>Contact</SectionTitle>
      <div>
        <div>
          <Label htmlFor="contact_person">
            Accounts Payable Contact Person*
          </Label>
          <Input
            type="text"
            {...formik.getFieldProps("contact_person")}
            placeholder="First Name + Last Name"
            style={
              formik.touched.contact_person &&
              formik.errors.contact_person && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.contact_person && formik.errors.contact_person && (
            <ErrorText className={styles.errorText}>
              {formik.errors.contact_person}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="contact_person_phone">Phone*</Label>
          <Input
            type="text"
            name="contact_person_phone"
            id="contact_person_phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.contact_person_phone &&
              formik.errors.contact_person_phone && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.contact_person_phone &&
            formik.errors.contact_person_phone && (
              <ErrorText className={styles.errorText}>
                {formik.errors.contact_person_phone}
              </ErrorText>
            )}
        </div>
        <div>
          <Label htmlFor="contact_person_email">Email*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("contact_person_email")}
            style={
              formik.touched.contact_person_email &&
              formik.errors.contact_person_email && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.contact_person_email &&
            formik.errors.contact_person_email && (
              <ErrorText className={styles.errorText}>
                {formik.errors.contact_person_email}
              </ErrorText>
            )}
        </div>
      </div>

      <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
        <Button
          type="button"
          onClick={handleBack}
          className={styles.backButton}
        >
          <ChevronLeft /> Back
        </Button>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepThree;
