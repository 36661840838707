import React, { useState } from "react";
import { BecomeFleetCardMemberForm } from "../../components/parts/forms";
import { SectionTitle, Text } from "../../components/styledComponent";
import { Loader } from "../../components/ui";
import successGif from "../../assets/successGif.gif";
import styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";

const BecomeFleetCardMember = () => {
  const [loader, setLoader] = useState(false);
  const [successBox, setSuccessBox] = useState(false);
  return (
    <div className={`${styles.becomeFleetMemberPage} sectionContainer`}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className={styles.becomeFleetMemberInner}>
              <SectionTitle>Fleet Card Application Form</SectionTitle>
              {!successBox && (
                <BecomeFleetCardMemberForm
                  loader={(val) => setLoader(val)}
                  success={(val) => setSuccessBox(val)}
                />
              )}
              {loader && (
                <div className={styles.loaderBox}>
                  <Loader />
                </div>
              )}
              {successBox && (
                <div className={styles.successBox}>
                  <img src={successGif} alt="Success" />
                  <h3>
                    Thank you.
                    <br />
                    Your response was submitted successfully.
                  </h3>
                  <Text>
                    A member from our team will be reaching out shortly.
                  </Text>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BecomeFleetCardMember;
