import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Form,
  // Label,
  ErrorText,
  Button,
  SectionTitle,
  // Checkbox,
  Text,
} from "../../../../../styledComponent";
import styles from "../../style.module.scss";
import { ChevronLeft } from "react-bootstrap-icons";
import { BASE_URL, POST_API_URLS } from "../../../../../../utils/apiConstant";
import axios from "axios";
import {
  handleApiErrors,
  convertCurrencyToNumber,
} from "../../../../../../utils/helpers";
import { toast, ToastContainer } from "react-toastify";

const StepSeven = (props) => {
  // const [isChecked, setIsChecked] = useState(false);

  const validationSchema = Yup.object({
    aggred_to_terms: Yup.boolean().oneOf([true], "You must agree to proceed"),
  });

  const formik = useFormik({
    initialValues: {
      aggred_to_terms: props.savedValues?.aggred_to_terms || false,
    },
    validationSchema,
    onSubmit: (values) => {
      // props.data(values);
      console.log("values", {
        ...props.prevData.stepSix,
        ...props.prevData.stepOne,
        mailing_address: { ...props.prevData.stepTwo },
        payable_contact_person: { ...props.prevData.stepThree },
        owner_detail: { ...props.prevData.stepFour },
        banking_information: { ...props.prevData.stepFive },
        aggred_to_terms: values,
      });
      const formData = prepareFormData({
        ...props.prevData.stepSix,
        ...props.prevData.stepOne,
        hst_exempt:
          props?.prevData?.stepOne?.hst_exempt === "yes" ? true : false,
        daily_usage_per_card:
          props?.prevData?.stepSix?.daily_usage_per_card === "$0."
            ? 0
            : convertCurrencyToNumber(
                props?.prevData?.stepSix?.daily_usage_per_card
              ),
        aggred_to_terms: values?.aggred_to_terms,
        mailing_address: { ...props.prevData.stepTwo },
        payable_contact_person: { ...props.prevData.stepThree },
        owner_detail: { ...props.prevData.stepFour },
        banking_information: { ...props.prevData.stepFive },
      });

      formSubmitHandler(formData);
    },
  });

  // Function to prepare FormData with nested objects and files
  const prepareFormData = (data) => {
    const formData = new FormData();

    // Handle top-level fields
    Object.keys(data).forEach((key) => {
      // Skip nested objects and files, handle them separately
      if (typeof data[key] !== "object" || data[key] === null) {
        formData.append(key, data[key]);
      }
    });

    // Handle mailing_address
    if (data.mailing_address) {
      Object.keys(data.mailing_address).forEach((key) => {
        formData.append(`mailing_address[${key}]`, data.mailing_address[key]);
      });
    }

    // Handle payable_contact_person
    if (data.payable_contact_person) {
      Object.keys(data.payable_contact_person).forEach((key) => {
        formData.append(
          `payable_contact_person[${key}]`,
          data.payable_contact_person[key]
        );
      });
    }

    // Handle owner_detail
    if (data.owner_detail) {
      Object.keys(data.owner_detail).forEach((key) => {
        formData.append(`owner_detail[${key}]`, data.owner_detail[key]);
      });
    }

    // Handle banking_information
    if (data.banking_information) {
      Object.keys(data.banking_information).forEach((key) => {
        // Handle void_cheque file separately
        if (
          key === "void_cheque" &&
          data.banking_information[key] instanceof File
        ) {
          formData.append(
            "banking_information[void_cheque]",
            data.banking_information[key]
          );
        } else {
          formData.append(
            `banking_information[${key}]`,
            data.banking_information[key]
          );
        }
      });
    }

    // Handle hst_exempt_document file
    if (data.hst_exempt_document && data.hst_exempt_document instanceof File) {
      formData.append("hst_exempt_document", data.hst_exempt_document);
    }

    // Handle legal_consent
    // if (data.legal_consent) {
    //   Object.keys(data.legal_consent).forEach((key) => {
    //     formData.append(`legal_consent[${key}]`, data.legal_consent[key]);
    //   });
    // }

    return formData;
  };

  async function formSubmitHandler(data) {
    props.isSubmitting(true);

    // const formRes = await commonPostApiHandler(
    //   POST_API_URLS.FLEET_CARD_MEMBER_FORM,
    //   data
    // );
    // formRes && formRes.response && props.isSuccess(true);
    try {
      await axios.post(
        `${BASE_URL + POST_API_URLS.FLEET_CARD_MEMBER_FORM}`,
        data
      );
      props.isSuccess(true);
      props.isSubmitting(false);
    } catch (error) {
      props.isSubmitting(false);

      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage || "Something went wrong", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.4rem", fontWeight: 500 },
        });
      });
    }
  }

  const handleBack = () => {
    // props.data(formik.values);
    props.prev();
  };

  // Extract business name and other details from previous steps
  const businessName = props.prevData?.stepOne?.business_name || "";
  // const firstName = props.prevData?.stepFour?.first_name || "";
  // const lastName = props.prevData?.stepFour?.last_name || "";
  const fullName = `${props.prevData.stepThree.contact_person}`;
  const title = props.prevData?.stepThree?.contact_person_title || "";

  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        className={styles.becomeFleetMemberForm}
      >
        <SectionTitle className={styles.formTitle}>Legal Sign Off</SectionTitle>

        <div className={styles.aggred_to_termsText}>
          <Text>
            By clicking below, I, <b>{fullName}</b>, in my capacity as{" "}
            <b>{title}</b> of <b>{businessName}</b>, hereby authorize Canco
            Petroleum Ltd. to conduct a credit check on my personal credit
            history as part of the application process for a fleet card issued
            to <b>{businessName}</b>. I understand that my personal credit
            information will be used to assess the financial stability and
            creditworthiness of the business. In addition, I acknowledge that I
            am personally guaranteeing the repayment of all obligations
            associated with the fleet card, including any balances, fees, or
            penalties, in the event that <b>{businessName}</b> fails to meet its
            financial obligations.
          </Text>
        </div>

        <div>
          <div className={styles.checkboxContainer}>
            {/* <Checkbox
                name={"t_and_c_accepted"}
                label={"I accept the Terms and Conditions*"}
                onChange={formik.handleChange("t_and_c_accepted")}
                onBlur={formik.handleBlur("t_and_c_accepted")}
              /> */}
            <label className="material-checkbox">
              <input
                type="checkbox"
                name={"aggred_to_terms"}
                id={"aggred_to_terms"}
                onChange={formik.handleChange("aggred_to_terms")}
                onBlur={formik.handleBlur("aggred_to_terms")}
                // defaultChecked={true}
              />
              <span className="checkmark"></span>
              <span>
                <span>I</span>&nbsp;
                <b>{fullName}</b>, understand the condition and wish to proceed.
              </span>
            </label>
            {formik.touched.aggred_to_terms &&
              formik.errors.aggred_to_terms && (
                <ErrorText
                  className={styles.errorText}
                  style={{ textAlign: "center" }}
                >
                  {formik.errors.aggred_to_terms}
                </ErrorText>
              )}
          </div>
        </div>

        <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
          <Button
            type="button"
            onClick={handleBack}
            className={styles.backButton}
          >
            <ChevronLeft /> Back
          </Button>
          <Button
            type="submit"
            disabled={!formik.values.aggred_to_terms}
            className={
              !formik.values.aggred_to_terms ? "disabledBtn" : styles.submitBtn
            }
          >
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
};

export default StepSeven;
