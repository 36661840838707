import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
// import { fleetMemberStepFiveInitialValues } from "../../../../../../utils/formik/initialvalue";
import { fleetMemberStepFiveValidationSchema } from "../../../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
  SectionTitle,
} from "../../../../../styledComponent";
import { normalizeInput } from "../../../../../../utils/helpers";
// import { POST_API_URLS } from "../../../../../../utils/apiConstant";
// import { commonPostApiHandler } from "../../../../../../api/commonApiCall";
import styles from "../../style.module.scss";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const StepFive = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      financial_institution: props.savedValues?.financial_institution || "",
      branch: props.savedValues?.branch || "",
      street: props.savedValues?.street || "",
      apartment: props.savedValues?.apartment || "",
      city: props.savedValues?.city || "",
      province: props.savedValues?.province || "",
      first_name: props.savedValues?.first_name || "",
      last_name: props.savedValues?.last_name || "",
      phone: props.savedValues?.phone || "",
      account_number: props.savedValues?.account_number || "",
      void_cheque: props.savedValues?.void_cheque || "",
    },
    validationSchema: fleetMemberStepFiveValidationSchema,
    onSubmit: (values) => {
      props.data(values);
      props.next();
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  const handleBack = () => {
    props.data(formik.values);
    props.prev();
  };

  useEffect(() => {
    setPhoneNumber(props.savedValues?.phone || "");
  }, [props.savedValues]);

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className={styles.becomeFleetMemberForm}
    >
      <SectionTitle className={styles.formTitle}>
        Banking Information
      </SectionTitle>
      <div>
        <div>
          <Label htmlFor="financial_institution">Financial Institution*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("financial_institution")}
            style={
              formik.touched.financial_institution &&
              formik.errors.financial_institution && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.financial_institution &&
            formik.errors.financial_institution && (
              <ErrorText className={styles.errorText}>
                {formik.errors.financial_institution}
              </ErrorText>
            )}
        </div>
        <div>
          <Label htmlFor="account_number">Account Number*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("account_number")}
            style={
              formik.touched.account_number &&
              formik.errors.account_number && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.account_number && formik.errors.account_number && (
            <ErrorText className={styles.errorText}>
              {formik.errors.account_number}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="branch">Branch*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("branch")}
            style={
              formik.touched.branch &&
              formik.errors.branch && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.branch && formik.errors.branch && (
            <ErrorText className={styles.errorText}>
              {formik.errors.branch}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="street">Street*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("street")}
            style={
              formik.touched.street &&
              formik.errors.street && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.street && formik.errors.street && (
            <ErrorText className={styles.errorText}>
              {formik.errors.street}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="apartment">Apt</Label>
          <Input
            type="text"
            {...formik.getFieldProps("apartment")}
            style={
              formik.touched.apartment &&
              formik.errors.apartment && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
            placeholder="If Applicable"
          />
          {formik.touched.apartment && formik.errors.apartment && (
            <ErrorText className={styles.errorText}>
              {formik.errors.apartment}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="city">City*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("city")}
            style={
              formik.touched.city &&
              formik.errors.city && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.city && formik.errors.city && (
            <ErrorText className={styles.errorText}>
              {formik.errors.city}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="province">Province*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("province")}
            style={
              formik.touched.province &&
              formik.errors.province && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.province && formik.errors.province && (
            <ErrorText className={styles.errorText}>
              {formik.errors.province}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="first_name">First Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("first_name")}
            style={
              formik.touched.first_name &&
              formik.errors.first_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.first_name}
            </ErrorText>
          )}
        </div>
        <div>
          <Label htmlFor="last_name">Last Name*</Label>
          <Input
            type="text"
            {...formik.getFieldProps("last_name")}
            style={
              formik.touched.last_name &&
              formik.errors.last_name && {
                boxShadow: "0px 0px 0px 2px red",
              }
            }
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <ErrorText className={styles.errorText}>
              {formik.errors.last_name}
            </ErrorText>
          )}
        </div>
      </div>
      <div>
        <div>
          <Label htmlFor="phone">Phone*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="(xxx) xxx-xxxx"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
            }}
            value={phoneNumber}
            style={
              formik.touched.phone &&
              formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
            }
          />
          {formik.touched.phone && formik.errors.phone && (
            <ErrorText className={styles.errorText}>
              {formik.errors.phone}
            </ErrorText>
          )}
        </div>
      </div>
      <div className={styles.uploadFileContainer}>
        <div className="uploadLogoContainer uploadVendorFile">
          <div className="uploadContainer">
            <input
              type="file"
              name="void_cheque"
              onChange={(e) => {
                formik.setFieldValue("void_cheque", e.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur("void_cheque")}
            />
            <label>Upload</label>
            <span id="fileChosen">
              {formik.values.void_cheque?.name || "Upload Void Cheque"}
            </span>
          </div>
        </div>
        {formik.touched.void_cheque && formik.errors.void_cheque && (
          <ErrorText className={styles.errorText}>
            {formik.errors.void_cheque}
          </ErrorText>
        )}
      </div>
      <div className={`${styles.formBtnContainer} ${styles.twoButtons}`}>
        <Button
          type="button"
          onClick={handleBack}
          className={styles.backButton}
        >
          <ChevronLeft /> Back
        </Button>
        <Button type="submit">
          Next <ChevronRight />
        </Button>
      </div>
    </Form>
  );
};

export default StepFive;
